<template>
  <picture>
    <source ref="source" type="image/webp" v-lazy-load :data-srcset="getWebpSrcset" />
    <img
      ref="img"
      :width="width"
      :height="height"
      v-lazy-load :data-src="getFallback"
      :sizes="getSizes"
      v-lazy-load :data-srcset="getJpgSrcset"
      :alt="alt"
      @error="onError"
    />
  </picture>
</template>

<script>
export default {
  props: {
    personId: {
      type: [String, Number],
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "100%",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    // joszaki-profile-photos-original-v2-dev -> original
    // joszaki-profile-photos-v2-dev -> converted
    return {
      sourceWidths: [112, 128, 192, 256, 336, 448],
    };
  },
  computed: {
    getFallback() {
      return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_BUCKET}/${this.personId}`;
    },
    getSizes() {
      return "(max-width: 419px) 64px, 112px";
    },
    getWebpSrcset() {
      return this.sourceWidths.map((size) => {
        // return `https://via.placeholder.com/${size}.webp/FFFFFF/FF0000 ${size}w`
        return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_CONVERTED_BUCKET}/${this.personId}_${size}.webp ${size}w`;
      });
    },
    getJpgSrcset() {
      return this.sourceWidths
        .map((size) => {
          // return `https://via.placeholder.com/${size}.jpg ${size}w`
          return `${process.env.GOOGLE_STORAGE_URL}/${process.env.GOOGLE_STORAGE_PROFILE_CONVERTED_BUCKET}/${this.personId}_${size}.jpg ${size}w`;
        })
        .join(", ");
    },
  },
  methods: {
    onError() {
      try {
        this.$refs.source.srcset = "";
        this.$refs.img.srcset = "";
        this.$refs.img.src =
          "https://joszaki.hu/boost/img/avatars/szaki-default.jpg";
        this.$emit("error");
      } catch (error) {
        this.$sentry.captureException(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  display: block;
  object-fit: cover;
}
</style>
