<template>
  <JoszakiAutocomplete
    ref="cityAutocomplete"
    v-model="cityFilter"
    :icon="icon"
    :items="filteredCities"
    v-bind="$attrs"
    :keep-first="keepFirst"
    :placeholder="value?.name ?? placeholder ?? ''"
    :formatter="(item) => item.name"
    :label="showLabel ? label ?? $t('common.city') : null"
    @focus="onFocus"
    @blur="onBlur"
    @select="select"
  >
    <template #empty>
      {{ $t("cityAutocomplete.empty") }}
    </template>
  </JoszakiAutocomplete>
</template>

<script>
import { debounce } from "~/helpers/debounce";

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    keepFirst: {
      type: Boolean,
      default: true,
    },
    filterCapitol: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "map-marker-alt",
    },
    placeholder: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    typingTrackerEvent: {
      type: String,
      default: null,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      cityFilter: "",
      citySearch: "",
      openOnFocus: false,
    };
  },
  computed: {
    isClientReady() {
      return process.client;
    },
    cities() {
      return this.$store.getters["data/cities"];
    },
    normalizedCityFilter() {
      return this.$helpers.normalizeInput(this.cityFilter);
    },
    filteredCities() {
      let result = [];
      if (this.cities) {
        const rankedList = [];
        for (const city of this.cities) {
          if (this.filterCapitol && city.id === "2") {
            continue;
          }
          const index = this.$helpers
            .normalizeInput(city.name)
            .indexOf(this.normalizedCityFilter);
          if (index > -1) {
            rankedList.push({
              index,
              city,
            });
          }
        }
        rankedList.sort((a, b) => {
          if (a.index === b.index) {
            if (a.city.parentCityId && b.city.parentCityId) {
              return a.city.id > b.city.id ? 1 : -1;
            }
            return a.city.name > b.city.name ? 1 : -1;
          }
          return a.index > b.index ? 1 : -1;
        });
        result = rankedList.map((x) => x.city);
      }
      return result;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.cityFilter = "";
          return;
        }
        if (newValue.name !== this.cityFilter) {
          this.cityFilter = newValue.name;
        }
      },
    },
    cityFilter(...args) {
      if (this.typingTrackerEvent) {
        this.debouncedTrackTyping(...args);
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      this.focusInput();
    }

    if (this.typingTrackerEvent) {
      this.debouncedTrackTyping = debounce((newValue, _oldValue) => {
        this.$trackers.trackEvent({
          title: this.typingTrackerEvent,
          data: { value: newValue },
        });
      }, 500);
    }
  },
  methods: {
    focusInput() {
      try {
        this.$refs.cityAutocomplete.focusInput();
      } catch (error) {
        console.info("Cant auto focus cityAutocomplete input");
      }
    },
    onFocus() {
      this.openOnFocus = false;
      this.cityFilter = this.citySearch;
      this.$emit("focus");
    },
    onBlur() {
      this.citySearch = this.cityFilter;
      this.cityFilter = this.value?.name ?? "";
      this.$emit("blur");
    },
    select(city) {
      this.citySearch = "";
      if (city) {
        this.$emit("select", city);
      } else {
        this.blur();
      }
    },
    blur() {
      this.openOnFocus = !this.openOnFocus;
      this.$refs.cityAutocomplete.isActive =
        !this.$refs.cityAutocomplete.isActive;
    },
  },
};
</script>
